/** @jsxImportSource @emotion/react */

const MessageForm = ({ userInput, onInputChange, onSubmit }) => {
  return (
    <form
      css={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        background: '#2a2a2a',
        padding: '20px',
        width: '100%',
        boxSizing: 'border-box',    
      }}
      onSubmit={onSubmit}
    >
      <input
        css={{
          flex: 1,
          padding: '10px',
          borderRadius: '10px',
          marginRight: '10px',
          fontSize: '1em',
          backgroundColor: 'transparent',
          border: '1px solid #444444',
          outline: 'none',
          color: '#fff',
        }}
        type="text"
        placeholder="Type your message here..."
        required
        value={userInput}
        onChange={onInputChange}
      />
      <button 
        css={{
          background: '#3498db',
          color: '#fff',
          border: 'none',
          padding: '10px 14px',
          borderRadius: '6px',
          cursor: 'pointer',
          fontSize: '0.9em',      
        }}
        type="submit"
      >
        Send
      </button>
    </form>
  );
};

export default MessageForm;
