/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import { Link, Outlet } from "react-router-dom";
import { useAuth } from '../providers/AuthProvider';
import Chat from '../shared/Chat';
import RegistrationForm from '../shared/RegistrationForm';
import AppHeader from '../shared/AppHeader';
import AppFooter from '../shared/AppFooter';
import logo from '../../images/logo.png';

function App() {
  const { isCheckingAuthStatus, isAuthenticated } = useAuth();
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

  const updateHeight = () => {
    setViewportHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', updateHeight);
    return () => window.removeEventListener('resize', updateHeight);
  }, []);

  if (isCheckingAuthStatus) return null;

  return (
    <div
      css={{
        position: 'relative',
        height: `${viewportHeight}px`,
        width: '100vw',
      }}
    >
      { isAuthenticated && (
        <div
          css={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 1,
          }}
        >
          <AppHeader />
          <Chat/>
          <AppFooter />
        </div>
      )}
      { !isAuthenticated && (
        <div
          css={{
            backgroundImage: 'url(https://cdn.discordapp.com/attachments/1039227859845263420/1167901488664817755/thatjokerswild_photo_of_the_stage_from_the_crowds_point_of_view_1f17e3d3-223d-44a9-8865-5f4ef9867631.png?ex=654fd005&is=653d5b05&hm=f94d3ea9fac51a21283781490e9e4a38e808bd97723da5c5a47551e0bd5773a2&)',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            width: '100%',
            height: '100%',
          }}
        >
          <div
            css={{
              width: '100%',
              height: '100%',
              background: 'linear-gradient(rgba(0,0,0,0.1),rgba(0,0,0,0.8), rgba(0,0,0,0.9))'
            }}
          >
            <div
              css={{
                position: 'absolute',
                top: 0,
                left: 0,
                bottom: 0,
                width: '100%',
                zIndex: 1,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div
                css={{
                  padding: '20px',
                  color: '#fff',
                  textAlign: 'center',
                  marginTop: 24,     
                }}
              >
                <img src={logo} alt="logo" height="100" />
              </div>   
              <RegistrationForm /> 
              <div
                css={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '20px',
                  textAlign: 'center',
                  alignItems: 'flex-end',
                  justifyContent: 'center',
                  flex: 1,
                  paddingBottom: 20,
                  'a': {
                    fontSize: '11px',
                    color: '#999',
                    textDecoration: 'none',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  },
                }}
              >
                <Link to='/terms'>Terms of Use</Link>
                <Link to='privacy'>Privacy Policy</Link>
              </div>  
            </div>
          </div>
        </div>
      )}
      <div><Outlet/></div>
    </div>
  );
}

export default App;
