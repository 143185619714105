/** @jsxImportSource @emotion/react */
import { useState } from 'react';

const SignUpForm = ({ 
  onError = () => {},
  onSubmit = () => {},
  onSuccess = () => {},
}) => {

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [emailAddress, setEmailAddress] = useState('');
  const [isEmailInputLocked, setIsEmailInputLocked] = useState(false);
  const [didRequestFail, setDidRequestFail] = useState(false);
  const [message, setMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsSubmitting(true);
    setIsEmailInputLocked(true);
    setMessage('');
    setDidRequestFail(false);
    onSubmit({ emailAddress });

    // POST the form to the server
    try {
      const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/email/register`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'X-Host': process.env.REACT_APP_HOSTNAME // this is a workaround because on the server req.headers.host does not include the subdomain
        },
        body: JSON.stringify({ emailAddress })
      });

      const data = await response.json();
      setMessage(data.message);

      if (data.status === 'error') {
        setDidRequestFail(true);
        onError(data.message);
        return;
      }

      onSuccess();

    } catch (error) {

      console.error(error);

      setDidRequestFail(true);
      setMessage('Server error. Please try again.');
      setIsEmailInputLocked(false);
      onError('Server error. Please try again.');

    } finally {

      setIsSubmitting(false);

    }
  };

  return (
    <form
      css={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderRadius: '10px',
        maxWidth: '100vw',
      }}
      onSubmit={handleSubmit}
    >
      <input
        autoFocus
        css={inputStyle}
        type="email"
        placeholder="Email Address"
        required
        value={emailAddress}
        onChange={(e) => setEmailAddress(e.target.value)}
        disabled={isEmailInputLocked}
      />
      <button 
        css={{
          ...buttonStyle,
          ...(emailAddress) && activeButtonStyle,
          ...(isSubmitting) && submittingButtonStyle,
        }} 
        disabled={isSubmitting}
        type="submit"
      >
        { isSubmitting ? 'Working...' : 'Start' }
      </button>
      {message && (
        <div 
          css={{
            ...messageStyle,
            ...didRequestFail && errorStyle
          }}
        >
          {message}
        </div>
      )}
    </form>
  );
};

const inputStyle = {
  color: '#fff',
  padding: '10px',
  border: '1px solid #0d0d0d',
  borderRadius: '8px',
  marginBottom: '10px',
  fontSize: '1em',
  width: '100%',
  maxWidth: '400px',
  boxSizing: 'border-box',
  backgroundColor: '#010101',
  '&::placeholder': {
    color: '#666',
  },
  '&:focus-visible': {
    outline: 'none',
  }
};

const buttonStyle = {
  background: '#2a2a2a',
  color: '#0a0a0a',
  padding: '10px 20px',
  borderRadius: '8px',
  cursor: 'pointer',
  width: 'auto',
  boxSizing: 'border-box',
  marginTop: '16px',
  border: '1px solid #1a1a1a',
};

const activeButtonStyle = {
  background: '#2a2a2a',
  color: '#ffffff',
}

const submittingButtonStyle = {
  backgroundColor: '#0e0e0e',
  color: '#999',
  border: '1px solid #0a0a0a',
}

const messageStyle = {
  backgroundColor: '#0e0e0e',
  padding: '10px 16px',
  fontSize: '0.9rem',
  borderRadius: '8px',
  color: 'lightgreen',
  border: '1px solid #0a0a0a',
  marginTop: '20px',
}

const errorStyle = {
  color: 'crimson',
}

export default SignUpForm;
