/** @jsxImportSource @emotion/react */
import GenericPage from '../../shared/GenericPage';

function TermsOfUse() {

  return (
    <div>
      <GenericPage>
        <div css={privacyStyle}>
          <h1>Terms of Use</h1>
  
          <h2>1. Acceptance of Terms</h2>
          <p>By accessing and using our website and the AI chatbot ("Services"), you acknowledge and agree to these Terms of Use. If you disagree with any part of these terms, please discontinue your use of our Services.</p>

          <h2>2. Description of Services</h2>
          <p>We offer marketing advice and materials via an AI chatbot. While we endeavor for accuracy and relevance, the AI chatbot is based on algorithms and data, and we cannot guarantee the appropriateness or accuracy of its advice for every individual situation.</p>

          <h2>3. User Responsibilities</h2>
          <ul>
              <li>You must use our Services in compliance with all applicable local, state, and federal laws.</li>
              <li>You agree to provide accurate information when interacting with our AI chatbot and not to misuse our Services for fraudulent or harmful purposes.</li>
              <li>Decisions made based on our AI chatbot's advice are at your discretion. We recommend seeking additional counsel or verification before taking significant actions based on the AI's recommendations.</li>
          </ul>

          <h2>4. Limitation of Liability</h2>
          <p>To the fullest extent permitted by Texas law, neither Amplifai, LLC nor its affiliates will be liable for any indirect, incidental, special, consequential, or punitive damages resulting from your use or inability to use our Services, even if we've been advised of the possibility of such damages.</p>

          <h2>5. Intellectual Property</h2>
          <p>All content, including but not limited to the AI chatbot's responses, graphics, logos, and texts on our website, is the property of Amplifai, LLC and is protected by United States and Texas intellectual property laws. Unauthorized use, reproduction, or distribution is strictly prohibited.</p>

          <h2>6. Termination</h2>
          <p>We reserve the right, at our discretion, to suspend or terminate your access to our Services for violations of these Terms or for any other reason.</p>

          <h2>7. Governing Law</h2>
          <p>These Terms of Use are governed by and construed in accordance with the laws of the state of Texas, without regard to its conflict of law principles.</p>

          <h2>8. Changes to Terms</h2>
          <p>We reserve the right to modify or replace these Terms at any time. Updated terms will be posted on our website, and continued use after such updates constitutes acceptance of the new terms.</p>

          <h2>9. Dispute Resolution</h2>
          <p>Any disputes arising out of or related to these Terms or our Services will be resolved in the state or federal courts located in Texas, and you consent to the jurisdiction of such courts.</p>

          <h2>10. Contact</h2>
          <p>For any questions or concerns about these Terms of Use, please contact us at heyamplifai@gmail.com.</p>
        </div>
      </GenericPage>
    </div>
  );
}

const heading1Style = {
  fontSize: '1.8em',
  borderBottom: '2px solid #f2f2f2',
  paddingBottom: '10px',
  marginBottom: '20px',
  color: '#f9c74f', // A gold shade for the main title
};

const heading2Style = {
  fontSize: '1.4em',
  margin: '20px 0',
  color: '#90be6d', // A light green shade for subheadings
};

const paragraphStyle = {
  lineHeight: '1.6',
  marginBottom: '15px',
};

const listStyle = {
  paddingLeft: '20px',
  lineHeight: '1.6',
  marginBottom: '20px',
};

const linkStyle = {
  textDecoration: 'underline',
  color: '#4d908e', // A teal shade for links
  cursor: 'pointer',
};

const privacyStyle = {
  maxWidth: '800px',
  margin: '0 auto',
  padding: '20px',
  background: '#1a1a1a',
  color: '#f2f2f2',
  fontFamily: 'Arial, sans-serif',
  '& h1': heading1Style,
  '& h2': heading2Style,
  '& p': paragraphStyle,
  '& ul': listStyle,
  '& a': linkStyle,
};

export default TermsOfUse;
