/** @jsxImportSource @emotion/react */
import { useAuth } from '../../providers/AuthProvider';

const SignOutForm = () => {

  const { 
    isLoggingOut,
    logout 
  } = useAuth();

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        logout();
      }}
    >
      <button css={buttonStyle} type="submit">
        {isLoggingOut ? 'Signing out..' : 'Sign out'}
      </button>
    </form>
  );
};

const buttonStyle = {
  background: '#1a1a1a',
  color: '#fff',
  border: 'none',
  padding: '8px 14px',
  borderRadius: '5px',
  cursor: 'pointer',
  fontSize: '0.9em',
  width: 'auto',
  boxSizing: 'border-box',
};

export default SignOutForm;
