/** @jsxImportSource @emotion/react */
import { useState, useEffect, useRef } from 'react';
import Message from './Message';
import MessageForm from './MessageForm';  

export const ChatLoader = async () => {
  return await fetch(`${process.env.REACT_APP_API_HOST}/api/catch-up`, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },  
  });
}

const Chat = ({ phoneNumber }) => {
  
  // const { messages } = useLoaderData();
  const [messages, setMessages] = useState([]);
  const [userInput, setUserInput] = useState('');
  const [loading, setLoading] = useState(false);
  const conversationRef = useRef(null);

  useEffect(() => {
    // Fetch the conversation history when the component is mounted
    const fetchConversation = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/catch-up`, {
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },  
        });
        const data = await response.json();
        setMessages(data.messages);
      } catch (error) {
        console.error('Error fetching conversation history:', error);
      }
    };

    fetchConversation();
  }, [phoneNumber]);

  const sendMessage = async (event) => {
    event.preventDefault();
    setLoading(true);

    // immediately set the user message and clear the input
    const tempMessages = [...messages, { role: 'user', content: userInput }];
    setMessages(tempMessages);
    setUserInput('');

    try {
      const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/message`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ Body: userInput })
      });

      const data = await response.json();
      setMessages([...tempMessages, 
        { role: 'AI', content: data.ai_response }
      ]);

      // Scroll to the bottom of the chat after adding new messages
      if (conversationRef.current) {
        conversationRef.current.scrollTop = conversationRef.current.scrollHeight;
      }

    } catch (error) {
      console.error('Error sending message:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (conversationRef.current) {
        conversationRef.current.scrollTop = conversationRef.current.scrollHeight;
    }
  }, [messages]);

  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };

  return (
    <div 
      css={{
        position: 'absolute',
        top: 50,
        left: 0,
        right: 0,
        bottom: 24,
      }}
    >
      <div
        css={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 77,
          wordWrap: 'break-word',
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'auto',
          padding: '20px 0px 20px',      
        }} 
        ref={conversationRef}
      >
        {messages.map((message, index) => (
          <Message key={index} message={message} />
        ))}
        { loading && <Message key="loading" message={{ role: 'AI', content: '...' }} />}
      </div>
      <MessageForm 
        userInput={userInput}
        onInputChange={handleInputChange}
        onSubmit={sendMessage}
      />
    </div>
  );
}

export default Chat;
