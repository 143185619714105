/** @jsxImportSource @emotion/react */

function GenericPage({ children }) {

  return (
    <div>
      <div 
        css={{
          position: 'relative',
          height: '100vh',
          width: '100vw',
          overflow: 'auto',
        }}
      >
        {children}
      </div>
    </div>
  );
}

export default GenericPage;
