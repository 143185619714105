/** @jsxImportSource @emotion/react */
import { Link } from "react-router-dom";

const AppHeader = () => {
  
  return (
    <div
      css={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        display: 'grid',
        gridTemplateColumns: '1fr auto 1fr',
        height: '24px',
        padding: '4px 8px',
        background: '#3a3a3a',
        boxSizing: 'border-box',
      }}
    >
      <div
        css={{
          color: '#999',
          fontSize: '11px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          paddingLeft: 14
        }}
      >
        &copy; Amplifai, LLC
      </div>
      <div></div>
      <div
        css={{
          color: '#999',
          fontSize: '11px',
          display: 'flex',
          flexDirection: 'row',
          gap: '16px',
          justifyContent: 'flex-end',
          paddingRight: 14,
          'a': {
            color: '#999',
            textDecoration: 'none',
            '&:hover': {
              textDecoration: 'underline',
            },
          },
        }}
      >
        <Link to='/terms'>Terms of Use</Link>
        <Link to='privacy'>Privacy Policy</Link>
      </div>
    </div>
  );
}

export default AppHeader;
