/** @jsxImportSource @emotion/react */
import Loader from '../Loader';
import ReactMarkdown from 'react-markdown'

const Message = ({ message }) => {
  
  const userMessageStyle = {
    maxWidth: '70%',
    padding: '12px 16px',
    margin: '5px 12px',
    borderRadius: '20px',
    position: 'relative',
    lineHeight: '1.5',
    backgroundColor: '#0b93f6',
    color: 'white',
    alignSelf: 'flex-end',
    borderBottomRightRadius: '0px',
  };

  const aiMessageStyle = {
    maxWidth: '70%',
    padding: '12px 16px',
    margin: '5px 12px',
    borderRadius: '20px',
    position: 'relative',
    lineHeight: '1.5',
    backgroundColor: '#333',
    color: '#fff',
    alignSelf: 'flex-start',
    borderBottomLeftRadius: '0px',
  };

  const downloadLinkStyle = {
    display: 'inline-block',
    padding: '4px 8px',
    borderRadius: '4px',
    border: '1px solid rgba(255,255,255,0.3)',
    color: '#999',
    textDecoration: 'none',
    backgroundColor: 'rgba(0,0,0,0.3)',
  };

  const isPendingAIMessage = message.role === 'AI' && message.content === '...';

  // Custom link renderer for download links
  const renderLink = ({href, children}) => {
    const linkStyle = href.includes('https://amplifai-openai-files') ? downloadLinkStyle : {};

    // Return a custom link component
    return <a href={href} css={linkStyle}>{children}</a>;
  };

  return (
    <div css={message.role === 'user' ? userMessageStyle : aiMessageStyle}>
        {isPendingAIMessage && <Loader />}
        {!isPendingAIMessage && 
          <div 
            css={{
              'p, li': {
                margin: '0px',
                '&:not(:last-child)': {
                  marginBottom: '12px',
                },
              },
              'pre': {
                width: '100%',
                overflow: 'auto',
                whiteSpace: 'pre-wrap',
                wordWrap: 'break-word',
              },
            }}
          >
            <ReactMarkdown
              components={{
                a: renderLink
              }}
            >
              {message.content}
            </ReactMarkdown>
          </div>
        }
    </div>
);
}

export default Message;
