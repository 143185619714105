/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react';

const fade = keyframes`
  0%, 100% { opacity: 0.5; }
  50% { opacity: 1; }
`;

const Loader = () => {
  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        width: '100%',
      }}
    >
      {[...Array(3)].map((_, i) => (
        <div
          key={i}
          css={css`
            background-color: #999;
            width: 10px;
            height: 10px;
            border-radius: 50%; // Added the unit 'px'
            margin: 0 5px;
            animation: ${fade} 1.2s infinite;
            animation-delay: ${i * 0.4}s; // Explicitly set as a string value
          `}
        />
      ))}
    </div>
  );
};

export default Loader;
