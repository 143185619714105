/** @jsxImportSource @emotion/react */
import { useLoaderData, useNavigate } from "react-router-dom";
import { useAuth } from '../../providers/AuthProvider';
import { useEffect } from 'react';

export const LoginLinkLoader = async ({ request }) => {
  const token = new URL(request.url).searchParams.get('token');
  const emailAddress = new URL(request.url).searchParams.get('email');
  const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/login`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },  
    body: JSON.stringify({ emailAddress, token })
  });
  return response;
}

function LoginLink() {

  const { isAuthenticated, checkAuthenticationStatus } = useAuth();
  const { status, message } = useLoaderData();
  const navigate = useNavigate();

  useEffect(() => {
    if (status === 'succeeded') checkAuthenticationStatus();
    if (status === 'succeeded' || isAuthenticated) {
      return navigate('/');
    }
  }, [status, isAuthenticated, navigate, checkAuthenticationStatus]);

  return (
    <div css={{ color: '#fff' }}>
      { message }
    </div>
  );
}

export default LoginLink;
