/** @jsxImportSource @emotion/react */
import GenericPage from '../../shared/GenericPage';

function PrivacyPolicy() {

  return (
    <div>
      <GenericPage>
        <div css={privacyStyle}>
          <h1>Privacy Policy</h1>
      
          <h2>1. Collection of Information</h2>
          <p>We collect various types of information to provide and improve our Services, including:</p>
          <ul>
              <li>Information you provide directly to us, such as when you chat with our AI chatbot.</li>
              <li>Technical and usage information, like your IP address, browser type, and pages you visit on our site.</li>
          </ul>
      
          <h2>2. Use of Information</h2>
          <p>The information we collect is used for several purposes, including:</p>
          <ul>
              <li>Enhancing and personalizing your user experience.</li>
              <li>Responding to your requests or inquiries.</li>
              <li>Analyzing trends and user behavior to improve our Services.</li>
              <li>For marketing and promotional purposes, in compliance with applicable laws.</li>
          </ul>
      
          <h2>3. Sharing of Information</h2>
          <p>We value your privacy and do not sell or rent your personal information to third parties. However, we may share your information in the following circumstances:</p>
          <ul>
              <li>With third-party vendors or service providers who perform functions on our behalf.</li>
              <li>When required by law or in response to legal processes.</li>
              <li>For business transfers, such as a merger or acquisition.</li>
          </ul>
      
          <h2>4. Data Storage and Security</h2>
          <p>We implement various security measures to protect your information. However, no online service can guarantee 100% security. We encourage users to be cautious and protect their personal information.</p>
      
          <h2>5. Cookies</h2>
          <p>We use cookies and similar technologies to enhance user experience, track website usage, and for marketing purposes. You can control and manage cookies using your browser settings. Disabling cookies may affect the functionality of our Services.</p>
      
          <h2>6. User Rights</h2>
          <p>Depending on your jurisdiction, you may have rights regarding your personal data, including:</p>
          <ul>
              <li>Accessing, correcting, or deleting your data.</li>
              <li>Objecting to or limiting the use of your data.</li>
              <li>Withdrawing consent for data processing, if applicable.</li>
          </ul>
          <p>If you wish to exercise any of these rights, please contact us.</p>
      
          <h2>7. Changes to Privacy Policy</h2>
          <p>We may update this Privacy Policy periodically to reflect changes in our practices or for other operational, legal, or regulatory reasons. We encourage users to review our Privacy Policy regularly for updates.</p>
      
          <h2>8. Contact Us</h2>
          <p>If you have questions or concerns regarding this Privacy Policy, please contact us at heyamplifai@gmail.com.</p>
        </div>
      </GenericPage>
    </div>
  );
}

const heading1Style = {
  fontSize: '1.8em',
  borderBottom: '2px solid #f2f2f2',
  paddingBottom: '10px',
  marginBottom: '20px',
  color: '#f9c74f', // A gold shade for the main title
};

const heading2Style = {
  fontSize: '1.4em',
  margin: '20px 0',
  color: '#90be6d', // A light green shade for subheadings
};

const paragraphStyle = {
  lineHeight: '1.6',
  marginBottom: '15px',
};

const listStyle = {
  paddingLeft: '20px',
  lineHeight: '1.6',
  marginBottom: '20px',
};

const linkStyle = {
  textDecoration: 'underline',
  color: '#4d908e', // A teal shade for links
  cursor: 'pointer',
};

const privacyStyle = {
  maxWidth: '800px',
  margin: '0 auto',
  padding: '20px',
  background: '#1a1a1a',
  color: '#f2f2f2',
  fontFamily: 'Arial, sans-serif',
  '& h1': heading1Style,
  '& h2': heading2Style,
  '& p': paragraphStyle,
  '& ul': listStyle,
  '& a': linkStyle,
};

export default PrivacyPolicy;
