/** @jsxImportSource @emotion/react */
import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";

function SpotifyAuthed() {

  // there will be a access_token query param in the url
  // there is no refresh_token
  // we want to save the access_token to local storage
  // then redirect to the home page
  const urlParams = new URLSearchParams(window.location.search);
  const accessToken = urlParams.get('access_token');
  localStorage.setItem('spotifyAccessToken', accessToken);

  const navigate = useNavigate();

  useEffect(() => {
    return navigate('/');
  }, [navigate]);

  return null;

}

export default SpotifyAuthed;
