/** @jsxImportSource @emotion/react */
import logo from '../../../images/logo.png';
import SignOutForm from '../SignOutForm';
import { useAuth } from '../../providers/AuthProvider';

const AppHeader = () => {
  
  const { emailAddress } = useAuth();

  // const handleLogin = () => {
  //   window.location.href = `${process.env.REACT_APP_API_HOST}/api/start-spotify-oauth`;
  // };

  return (
    <div
      css={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        display: 'grid',
        gridTemplateColumns: '33px 1fr auto auto auto',
        height: '50px',
        background: '#2a2a2a',
        padding: 10,
        boxSizing: 'border-box',
      }}
    >
      <div>
        <img 
          src={logo} 
          alt="logo" 
          css={{
            height: 30,
          }}
        />
      </div>
      <div></div>
      {/* <div>
        <button onClick={handleLogin}>Spotify</button>
      </div> */}
      <div
        css={{
          color: '#999',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          paddingRight: '10px',
          fontSize: '0.9em',
        }}
      >
        {emailAddress}
      </div>
      <SignOutForm />
    </div>
  );
}

export default AppHeader;
