import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import AuthProvider from './components/providers/AuthProvider/index.js'

import App from './components/app/index.js';
import {default as LoginLink, LoginLinkLoader} from './components/routes/LoginLink/index.js';
import PrivacyPolicy from './components/routes/PrivacyPolicy/index.js';
import SpotifyAuthed from './components/routes/SpotifyAuthed/index.js';
import TermsOfUse from './components/routes/TermsOfUse/index.js';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>,
  },
  {
    path: "login-link",
    element: <LoginLink />,
    loader: LoginLinkLoader,
  },
  {
    path: "terms",
    element: <TermsOfUse />,
  },
  {
    path: "privacy",
    element: <PrivacyPolicy />,
  },
  {
    path: "spotify-authed",
    element: <SpotifyAuthed />,
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
